const config = {
  "broker": "3",
  "name": "polish",
  "registrationBundle": "polish",
  "defaultCountryName": "Poland",
  "defaultCountryCode": "PL",
  "fxgeoip_code": "PL",
  languageCode: 'pl',
  languageName:'Polish',
  spokenLanguageCode:'pl',
  direction:'ltr',
  footerDisclaimer:'898',
  brand: 'iforex'
};
module.exports = config;